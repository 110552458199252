import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Text } from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"

import { Button, Hero, SEO } from "../components"

const BlogPage = ({ data: { posts, blog } }) => {
  const { hero, seo } = blog.nodes[0]
  return (
    <>
      <SEO seo={seo} />
      <Hero
        text={hero.title}
        primary
        small
        fluid
        image={hero.image.childImageSharp.fluid}
      />
      <Box my={{ base: "20", lg: "32" }}>
        <Container>
          <Row>
            {posts.nodes.map((node, i) => {
              return (
                <Col component={Box} md={6} key={i} pb="16">
                  <Link to={`/blog/${node.slug.current}`}>
                    {node.mainImage && (
                      <Img
                        fluid={{
                          ...node.mainImage.asset.fluid,
                          aspectRatio: 3 / 2,
                        }}
                      />
                    )}
                    <Text py="6" fontSize={{ lg: "xl" }}>
                      {node.intro}
                    </Text>
                  </Link>
                  <Button as={Link} to={`/blog/${node.slug.current}`}>
                    Mehr erfahren
                  </Button>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Box>
    </>
  )
}

export const query = graphql`
  {
    blog: allBlogJson {
      nodes {
        hero {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        seo {
          description
          title
        }
      }
    }
    posts: allSanityPost(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        mainImage {
          asset {
            url
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        title
        intro
      }
    }
  }
`

export default BlogPage
